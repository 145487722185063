<template>

    <zw-sidebar @shown="shown" :title="$t('article.title.map_article')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>

                    <b-col sm="12">
                        <zw-file-group v-model="form.file"
                                       name="file"
                                       :label-prefix="labelPrefix"
                                       document-type="xlsx"
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ></zw-file-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="6" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwNewDateGroup from "../../components/ZwNewDateGroup";

export default {
    components: {ZwNewDateGroup},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'article.label.map_article_form.',
            payload: {},
            callback: null,
            form: {
                file: null,
            },
        }
    },
    methods: {
        ...mapGetters('ChannelArticle', ['getArticlesMap']),
        shown() {
            const mapFile = this.$store.dispatch('ChannelArticle/fetchArticlesMap').then((response) => {
                this.form.file = response.data.file;
            })
            this.loading = false
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('ChannelArticle/saveArticlesMap', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>